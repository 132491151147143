<template>
  <v-container fluid>
    <v-row justify="center">
      <v-container style="max-width: 1280px">
        <v-row dense>
          <v-col cols="2">
            <p style="font-size: 24px"><b>マイページ</b></p>
          </v-col>
          <v-col cols="4"></v-col>
          <v-col cols="3" align="end">
            <v-btn
                color="primary"
                @click="goToUpdateUser"
            >
              会員情報更新
            </v-btn>
          </v-col>
          <v-col cols="3" align="end">
            <v-btn
                color="gray"
                @click="backToHome"
            >
              ホームに戻る
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense class="mb-12" style="margin-top: -10px">
          <v-col cols="6" style="font-size: 16px !important;">登録ID:{{ this.loginId }}</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6"><b style="font-size: 20px">注文履歴</b></v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <div v-for="(body,index) in this.orderList" :key="index">
              <HistoryDetail :orderDetail="body"></HistoryDetail>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import HistoryDetail from '../components/CommonUtils/HistoryDetail'
import {mapGetters} from 'vuex'
import {getOrderList} from '@/domain/api/order'
import {getUserInfo} from "@/domain/api/user";

export default {
  name: "MyPage",
  components: {
    HistoryDetail
  },
  data() {
    return {
      orderList: null
    }
  },
  computed: {
    ...mapGetters({
      loginId: 'logininfo/loginId',
      userId: 'logininfo/userId',
    })
  },
  async mounted() {
    this.orderList = await getOrderList(this.userId);
    const userData = await getUserInfo(this.$store.state.logininfo.userId);
    await this.$store.dispatch('logininfo/setName', {
      name: userData.name.split(" ")[0] + " " + userData.name.split(" ")[1]
    });
  },
  methods: {
    backToHome() {
      if (this.$route.path != '/') {
        this.$router.push({
          name: "Home"
        });
      }
    },
    goToUpdateUser() {
      if (this.$route.path != '/updateUser') {
        this.$router.push({
          name: "UpdateUser"
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
