const namespaced = true;

const defaultData = {
    userId: "",
    loginId: "",
    token: "",
    name:""
};

const getState = () => ({
    ...defaultData
});

export default {
    namespaced,
    state: getState(),
    mutations: {
        setStatusLoginId(state, payload) {
            state.loginId = payload.loginId;
            state.token = payload.token;
            state.userId = payload.userId;
        },
        setStatusName(state,payload){
            state.name = payload.name;
        },
        init(state) {
            Object.assign(state, defaultData);
        }
    },
    getters: {
        userId: state => state.userId,
        loginId: state => state.loginId,
        token: state => state.token,
        name:state => state.name
    },
    actions: {
        setLoginId({commit}, {loginId, token, userId}) {
            commit("setStatusLoginId", {loginId, token, userId});
        },
        setName({commit}, {name}){
            commit("setStatusName", {name});
        },
        refresh({commit}) {
            commit("init");
        }
    }
}
