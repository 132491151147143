<template>
  <v-container style="max-width: 1280px;margin-top: 10px">
    <v-row>
      <v-col cols="4" class="mb-0 pb-0">
        <p style="cursor: pointer;" @click="goToHome">ホーム</p>
        <p style="cursor: pointer" @click="goToSendAndPay">配送・決済について</p>
      </v-col>
      <v-col cols="4" style="cursor: pointer" class="mb-0 pb-0">
        <p style="cursor: pointer" @click="goToProxy">プライバシーポリシー</p>
        <p style="cursor: pointer" @click="goToGoodInfo">特定商取引に関する表記</p>
      </v-col>
      <v-col cols="4" class="mb-0 pb-0">
        <p class="pa-0 ma-0">〒 101-0023 東京都千代田区神田松永町11 <br>AＴ第一ビル5階</p>
        <p class="pa-0 ma-0">☎️ 03-6262-9897</p>
        <p class="pa-0 ma-0">✉️ sale@akiba109.com</p>
      </v-col>
      <v-col class="mt-0 pt-0 pb-0" cols="12">
        <p class="pa-0 ma-0">©2024 株式会社 源 All Rights Reserved.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Foot",
  methods: {
    goToHome() {
      if (this.$route.path != '/') {
        this.$router.push({
          name: "Home"
        });
      }
    },
    goToSendAndPay() {
      if (this.$route.path != '/sendAndPayInfo') {
        this.$router.push({
          name: "SendPayInfo"
        });
      }
    },
    goToGoodInfo() {
      if (this.$route.path != '/goodInfo') {
        this.$router.push({
          name: "GoodInfo"
        });
      }
    },
    goToProxy() {
      if (this.$route.path != '/proxy') {
        this.$router.push({
          name: "Proxy"
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
